import React, { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import Reproductor from '../player/Reproductor';
import Spinner from '../spinner/Spinner';
import useIngles from '../../hooks/useIngles';
import '../content/Content.scss'

const Content = () => {
	const validaLibro = localStorage.getItem('texto');
	// const inglesState = useContext(inglesContext);
	const {cargando, autenticarUsuario, cerrarSesion} = useIngles();

	let navigate = useNavigate();

	useEffect(() => {
		if(!validaLibro){
			navigate('/');
			cerrarSesion();
		}
		
		setTimeout(() => {
			autenticarUsuario();
		}, 4000);
	},)


	// useEffect(() => {
	// 	setTimeout(() => {
	// 		autenticarUsuario();
	// 	}, 4000)
	// }, [autenticarUsuario]);

	return (
		<div className='_oGACf'>
			{
				(cargando) ? <Reproductor /> : <Spinner />
			}		
		</div>
	)
}

export default Content