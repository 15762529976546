import React from 'react';
import {
    BrowserRouter as Router,
	Routes,
	Route
} from 'react-router-dom';
import Login from './component/login/Login';
import Layout from './layout/Layout';
import { InglesProvider } from './context/InglesProvider';


const App = () => {
  return (
	<InglesProvider>
		<Router>
			<Routes>
				<Route exac path={'/'} element={ <Login />} />
				<Route exac path={'/contenido'} element={ <Layout />} />
			</Routes>
		</Router>
	</InglesProvider>
  )
}

export default App