import React from 'react';

import './Footer.scss';

const Footer = () => {
  return (
    <div className='pie-pagina'>
        <p>Editorial Mi Nuevo Mundo © 2023</p>
    </div>
  )
}

export default Footer