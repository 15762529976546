import React, {useState, useEffect} from 'react';
import logo from '../../imagenes/logoBM.png';
import welcome from '../../imagenes/welcome.png';
import useIngles from '../../hooks/useIngles';
import {useNavigate} from 'react-router-dom';

import './Login.css';

const Login = () => {
    const {alerta, formularioAlerta, mensajeErrorForm, loginUsuario, autenticado } = useIngles();
    // const stateIngles = useContext(inglesContext);
    // const {alerta, mensajeErrorForm, formularioAlerta, loginUsuario, autenticado} = stateIngles;
    let navigate = useNavigate();

    const [user, setUser] = useState({
        email: '',
        password: ''
    });

    const {email, password} = user;

    const onChange = (e) => {        
        setUser({
            ...user,
            [e.target.name]:[e.target.value]
        });    
    }

    const loguear = async(e) => {
        e.preventDefault();        

        if(email === '' || password === ''){
            formularioAlerta('Unrecognized email or password.');            
            return;
        }

        loginUsuario({
            email,
			password,
        });        
    }

    useEffect(() => {
        if(autenticado){
            navigate('/contenido');			
        }
    })

    return (
        <div className="Principal">    
        <div className="contentPrincipal">
            <div className="welcome">
                <img src={welcome} alt="bienvenida" />
            </div>
            <div className="contenidoLogin">
                    <div className="logo">
                        <img src={logo} alt="logo" />
                    </div>
                    <form onSubmit={loguear}>
                        <div className="nombreLogin">
                            <p>LOGIN</p>
                        </div>
                        <div className="datos_ingresar">
                            <p>Username</p>
                            <input 
                                type="text"
                                value={email}
                                name='email'
                                onChange={onChange} 
                            />
                        </div>
                        <div className="datos_ingresar">
                            <p>Password</p>
                            <input 
                                type="password"
                                value={password}
                                name='password'
                                onChange={onChange} 
                            />
                        </div>
                        <div className="btn">
                            <input type="submit" name="enviar" value="Login" />                            
                        </div>
                    </form>
                    <div className="error">
                        {
                            (alerta) ? mensajeErrorForm : null
                        }
                    </div>
                </div>
        </div>
    </div>
    )
}

export default Login