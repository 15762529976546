import React from 'react'
import Player from "@madzadev/audio-player";

import './Reproductor.scss';

const Reproductor = () => {
    const validaDatos = JSON.parse(localStorage.getItem('audios'));
    const nombreLibro = localStorage.getItem('texto');

    const colors = `html {
        --tagsBackground: #9440f3;
        --tagsText: #ffffff;
        --tagsBackgroundHoverActive: #2cc0a0;
        --tagsTextHoverActive: #ffffff;
        --searchBackground: #18191f;
        --searchText: #ffffff;
        --searchPlaceHolder: #575a77;
        --playerBackground: #18191f;
        --titleColor: #ffffff; 
        --timeColor: #ffffff;
        --progressSlider: #9440f3;
        --progressUsed: #ffffff;
        --progressLeft: #151616;
        --volumeSlider: #9440f3;
        --volumeUsed: #ffffff;
        --volumeLeft:  #151616;
        --playlistBackground: #18191f;
        --playlistText: #575a77;
        --playlistBackgroundHoverActive:  #18191f;
        --playlistTextHoverActive: #ffffff;
    }`;

    // const audios = validaDatos.filter(dataM => dataM.tags[0] === nombreLibro);
    const audios = validaDatos.filter(dataM => dataM.tags === nombreLibro);

    console.log(audios.length);

    const track = audios.map((item, index) => {
        return {
            url: item.url,
            title: item.title,
            tags: [item.tags]
        }
    })

    return (
        <Player 
            trackList={track}
            includeTags={false}
            ncludeSearch={true}
            showPlaylist={true}
            autoPlayNextTrack={true}
            customColorScheme={colors}
        />
    )
}

export default Reproductor