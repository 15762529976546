import React from 'react';
import Header from '../component/header/Header';
import Content from '../component/content/Content';
import Footer from '../component/footer/Footer';


import './Layout.scss';

const Layout = () => {
    return (
		<div className='content-layout'>
			<header>
				<Header />
			</header>
			<main>
                <Content /> 
			</main>
			<footer>
                <Footer />
			</footer>
		</div>
  	)
}

export default Layout