import logo from '../../imagenes/logoBM.png';
import { Button } from 'antd';
import useIngles from '../../hooks/useIngles';

import './Header.scss';

const Header = () => {
	const nombreLibro = localStorage.getItem('texto');
	const {cerrarSesion} = useIngles();

	return (
		<div className='menu-superior'>
			<div className='logo_editorial'>
				<img src={logo} alt='logo editorial'/>
				<p className='text_edi'>Editorial Mi Nuevo Mundo</p>
			</div>
			<div className='name_book'>
				<p className='text-libro'>{nombreLibro}</p>
				<Button type="primary" danger onClick={() => cerrarSesion()}>Cerrar Sesión</Button>
			</div>
		</div>
	)
}

export default Header;